import { Button } from 'antd';
import { formatISO } from 'date-fns';
import React, { FC, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TypeSettings } from '~constants';
import { SettingsContext } from '~providers';
import { usePublishPostMutation, useUpdateSettingsMutation } from '~services';
import { isDefined } from '~utils';

import style from './styles.module.scss';

export const SettingsSave: FC = () => {
  const [searchParams] = useSearchParams();
  const { forms, isReadyToSave, posts } = useContext(SettingsContext);
  const [updateSettings, { isLoading: isUpdatingSettings }] = useUpdateSettingsMutation();
  const [publishPost, { isLoading: isPublishingPost }] = usePublishPostMutation();

  const saveSettings = async () => {
    if (!isReadyToSave) return;

    const type = searchParams.get('type') as TypeSettings;
    // Сохраняем только текущую вкладку, тк при переключении вкладок компоненты форм размонтируются -> теряются данные из формы
    switch (type) {
      case TypeSettings.About: {
        const aboutPostId = posts.about.post?.id;
        if (isDefined(aboutPostId)) {
          await publishPost({ id: aboutPostId, publishedAt: formatISO(new Date()) });
        }
        break;
      }
      case TypeSettings.Ads: {
        const advertisersPostId = posts.advertisers.post?.id;
        if (isDefined(advertisersPostId)) {
          await publishPost({ id: advertisersPostId, publishedAt: formatISO(new Date()) });
        }
        break;
      }
      case TypeSettings.Contacts: {
        const contactsValues = forms.contacts.form.getFieldsValue();
        await updateSettings(contactsValues);
        break;
      }
      case TypeSettings.Branding: {
        const brandingValues = forms.branding.form.getFieldsValue();
        await updateSettings(brandingValues);
        break;
      }
      case TypeSettings.Socials: {
        const socialsValues = forms.socials.form.getFieldsValue();
        await updateSettings(socialsValues);
        break;
      }
      default:
        break;
    }
  };

  return isReadyToSave ? (
    <Button
      className={style.button}
      block
      type="primary"
      size="large"
      loading={isPublishingPost || isUpdatingSettings}
      onClick={saveSettings}
    >
      Сохранить
    </Button>
  ) : null;
};
