import { always, cond, equals } from 'ramda';

import { TypeSettings } from '~constants';

export const getSettingsTypeFilterTitle = cond([
  [equals(TypeSettings.About), always('О проекте')],
  [equals(TypeSettings.Socials), always('Социальные сети')],
  [equals(TypeSettings.Contacts), always('Контактная информация')],
  [equals(TypeSettings.Branding), always('Брендирование')],
  [equals(TypeSettings.Ads), always('Рекламодателям')],
]);
