import { always, cond, equals, T } from 'ramda';
import React, { ComponentType, Fragment } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TypeSettings } from '~constants';

import {
  SettingsTabAbout,
  SettingsTabAds,
  SettingsTabBranding,
  SettingsTabContacts,
  SettingsTabSocials,
} from './Tabs';

const getTabComponent = cond<[TypeSettings | string], ComponentType>([
  [equals(TypeSettings.About), always(SettingsTabAbout)],
  [equals(TypeSettings.Contacts), always(SettingsTabContacts)],
  [equals(TypeSettings.Socials), always(SettingsTabSocials)],
  [equals(TypeSettings.Branding), always(SettingsTabBranding)],
  [equals(TypeSettings.Ads), always(SettingsTabAds)],
  [T, always(Fragment)],
]);

export const SettingsTabs: React.FC = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type') || TypeSettings.About;
  const TabComponent = getTabComponent(type);
  return <TabComponent />;
};
