import { Col, Form, Input, Row } from 'antd';
import cn from 'classnames';
import { prop } from 'ramda';
import React, { useContext } from 'react';

import { DropType } from '~constants';
import { useIsMobile } from '~hooks';
import { SettingsContext } from '~providers';

import { DropZone } from '../../../DropZone';
import { SettingsTabWrapper } from '../Wrapper';
import style from './styles.module.scss';

export const SettingsTabBranding: React.FC = () => {
  const { forms } = useContext(SettingsContext);
  const isMobile = useIsMobile();
  const { form, initialValues, isError, isFetching } = forms.branding;
  return (
    <SettingsTabWrapper isError={isError} isFetching={isFetching} hasContent>
      <Form form={form} initialValues={initialValues} layout="vertical" style={{ width: '100%' }}>
        <Col md={12} xs={24} className={cn(!isMobile && style.noPaddingLeft)}>
          <Row gutter={[25, 25]}>
            <Col span={24}>
              <Form.Item
                name="siteTitle"
                /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                label={<label className={style.label}>Имя проекта</label>}
              >
                <Input placeholder="Введите имя проекта" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="primaryColor"
                /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                label={<label className={style.label}>Основной цвет</label>}
                rules={[
                  {
                    message: 'Введите цвет в формате hex(a)',
                    pattern: /#([a-f0-9]{8}|[a-f0-9]{6})\b/gi,
                  },
                ]}
              >
                <Input placeholder="Введите значение основного цвета" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="logoId"
                getValueFromEvent={prop('id')}
                getValueProps={() => ({
                  value: initialValues.logo,
                })}
                /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                label={<label className={style.label}>Лого</label>}
              >
                <DropZone
                  type={DropType.Image}
                  imageOptions={{
                    height: isMobile ? 177 : 240,
                    width: isMobile ? 315 : 420,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Form>
    </SettingsTabWrapper>
  );
};
